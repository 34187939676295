<template>
  <div class="collection-wrapper">
    <div class="search-content">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="组织单位">
          <el-input v-model="formInline.publishCmpName" placeholder="请输入组织单位" />
        </el-form-item>
        <el-form-item label="公告名称">
          <el-input v-model="formInline.projectName" placeholder="请输入公告名称" />
        </el-form-item>
        <el-form-item label="商品类别" label-width="80px">
          <el-cascader
            ref="goodsCategoryRef"
            v-model="formInline.categoryArr"
            placeholder="请选择商品类别"
            :options="categoryOption"
            :props="categoryCascadeProps"
            clearable
            @change="(value) => categoryChange(value)"
          />
        </el-form-item>
        <el-form-item label="交货省份" prop="placeProvince">
          <el-select v-model="formInline.placeProvince" class="m-2" placeholder="请选择项目地区" size="large">
            <el-option
              v-for="item in allCityOption"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公告状态">
          <el-radio-group v-model="formInline.status" @change="changeStatus">
            <el-radio :label="2">
              报名中
            </el-radio>
            <el-radio :label="3">
              报名截止
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="search-btn">
          <p @click="getdata('reset')">
            清空
          </p>
          <el-button @click="getdata">
            搜索
          </el-button>
          <!-- <div>
            <p>清空</p>
            <el-button type="primary" @click="search">
              查询
            </el-button>
          </div> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 排序 -->
    <div class="sortPageBox">
      <span @click="changeActive(1)">综合排序</span>
      <span :class="sortActive === 2 ? 'active' : ''" @click="changeActive(2)">发布时间
        <p class="icon">
          <i :class="publishTimeOrderBy === 1 ? 'el-icon-caret-top active' : 'el-icon-caret-top'" />
          <i :class="publishTimeOrderBy === 2 ? 'el-icon-caret-bottom active' : 'el-icon-caret-bottom'" />
        </p>
      </span>
      <span :class="sortActive === 3 ? 'active' : ''" @click="changeActive(3)">
        报名截止时间
        <p class="icon">
          <i :class="enterEndDateOrderBy === 1 ? 'el-icon-caret-top active' : 'el-icon-caret-top'" />
          <i :class="enterEndDateOrderBy === 2 ? 'el-icon-caret-bottom active' : 'el-icon-caret-bottom'" />
        </p>
      </span>
      <div class="collection-type">
        <img title="列表模式" :src="imgActiveInedex ? imgSrc.rowSrc : imgSrc.rowActiveSrc" alt="" @click="changeImgActiveIndex(0)">
        <img title="网络模式" :src="imgActiveInedex ? imgSrc.cardActiveSrc : imgSrc.cardSrc" alt="" @click="changeImgActiveIndex(1)">
      </div>
    </div>
    <template v-if="cardList.length">
      <!-- 集采卡片 -->
      <div v-if="imgActiveInedex" class="collection-card">
        <card v-for="(item, index) in cardList" :key="index" :collection-item="item" @click.native="gotoDetail(item)" />
      </div>
      <Row v-for="(item, index) in cardList" v-else :key="index" :collection-item="item" @click.native="gotoDetail(item)" />
    </template>
    <NoData v-else class="noData" :type="5" />
    <div class="collection-row" />
    <Pagination v-if="cardList.length>0" :get-data-list="getdata" :page-sizes="[12,24,36,60,72]" :params="formInline" :total="total" />
  </div>
</template>
<script>
import NoData from '@/components/NoData.vue'
import { getCollectionList } from '@/http/collection'
import Pagination from '@/components/Pagination.vue'
import Card from './components/card.vue'
import Row from './components/row.vue'
import { getSelectAreaTree } from '@/http/common'
import { goodsGetGoodsCategoryList } from '@/http/hangOut'
import rowSrc from '@/assets/img/row.png'
import rowActiveSrc from '@/assets/img/rowActive.png'
import cardSrc from '@/assets/img/card.png'
import cardActiveSrc from '@/assets/img/cardActive.png'
export default {
  components: { Card, Pagination, Row, NoData },
  data() {
    return {
      imgActiveInedex: 1,
      imgSrc: {
        rowSrc,
        rowActiveSrc,
        cardSrc,
        cardActiveSrc
      },
      total: 0,
      sortActive: 0,
      publishTimeOrderBy: 0,
      enterEndDateOrderBy: 0,
      formInline: {
        categoryArr: [],
        pageSize: 12,
        pageNum: 1,
        notEqProjectStatus: 1
      },
      allCityOption: [],
      categoryOption: [],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName',
        children: 'children',
        emitPath: true
      },
      cardList: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 切换公告状态
    changeStatus(val) {
      if (val === 2) {
        this.formInline.notEqProjectStatus = 1
        this.formInline.projectStatus = val
      } else {
        this.formInline.projectStatus = ''
        this.formInline.notEqProjectStatus = 2
      }
    },
    changeImgActiveIndex(index) {
      this.imgActiveInedex = index
      console.log(this.imgActiveInedex)
    },
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          notEqProjectStatus: 1,
          publishTimeOrderBy: 0,
          enterEndDateOrderBy: 0,
          pageSize: 12,
          pageNum: 1
        }
        this.publishTimeOrderBy = 0
        this.enterEndDateOrderBy = 0
        this.sortActive = 0
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      getCollectionList(this.formInline, (res) => {
        this.cardList = [...res.data.records]
        this.total = res.data.total
      })
    },
    init() {
      this.changeActive(0)
      // 获取集采列表
      this.getdata()
      // 获取所有的城市
      getSelectAreaTree('00', res => {
        this.allCityOption = [...res.data]
      })
      // 品类 级联选择器数据
      goodsGetGoodsCategoryList(res => {
        this.getTreeData(res.data)
        this.categoryOption = res.data
      })
    },
    // 递归处理级联数据
    getTreeData(data) {
      data.map((item) => {
        if (item.children.length === 0) {
          item.children = null
        } else {
          this.getTreeData(item.children)
        }
      })
    },
    // 级联品类change事件
    categoryChange(val, index) {
      // eslint-disable-next-line no-return-assign
      if (!val || val.length === 0) return this.goodsSpecOption = this.goodsMaterialOption = []
      const node = this.$refs['goodsCategoryRef'].getCheckedNodes()[0].pathNodes
      this.formInline.categoryId = node.length && node[0].value// 品名一级
      this.formInline.varietyId = node.length && node[1].value// 二级
      this.formInline.goodsId = node.length && node[2].value// 三级
    },
    // 点击排序
    changeActive(index) {
      this.sortActive = index
      switch (index) {
        case 2:
          this.enterEndDateOrderBy = 0
          this.publishTimeOrderBy += 1
          if (this.publishTimeOrderBy >= 3) {
            this.publishTimeOrderBy = 0
          }

          break
        case 3:
          this.publishTimeOrderBy = 0
          this.enterEndDateOrderBy += 1
          if (this.enterEndDateOrderBy >= 3) {
            this.enterEndDateOrderBy = 0
          }
          break
        default:
          this.publishTimeOrderBy = 0
          this.enterEndDateOrderBy = 0
          this.formInline.pageSize = 12
          this.formInline.pageNum = 1
          break
      }
      this.formInline.publishTimeOrderBy = this.publishTimeOrderBy
      this.formInline.enterEndDateOrderBy = this.enterEndDateOrderBy
      this.getdata()
    },
    // search() {
    //   console.log(this.formInline)
    // },
    gotoDetail(item) {
      this.$router.push({
        path: '/collection/notice',
        query: {
          projectId: item.projectId
        }
      })
    }
  }
}
</script>
<style lang="scss">
.noData {
    padding-bottom: 50px;
    .imageBox img {
      width: 200px !important;
      height: 200px !important;
      margin: 50px 0 !important;
    }
  }
</style>
<style lang="scss" scoped>
.collection-wrapper {
    width: $centerPlateWidth;
    margin: 18px auto 0;
    .noData{
      padding: 35px 0 100px;
    }
    .search-content {
        background: #fff;
        padding: 18px 24px;
        /deep/ .el-form-item {
            width: 32%;
        }
        /deep/ .el-form-item__content {
            width: 70%;
        }
        /deep/ .el-input, /deep/ .el-select, /deep/ .el-cascader {
            width: 100%;
        }
        /deep/ .el-radio__input.is-checked .el-radio__inner {
          background: #D31E0E;
          border-color: #D31E0E;
        }
        /deep/ .el-radio__input.is-checked+.el-radio__label {
          color: #000;
        }
        .search-btn {

            /deep/ .el-form-item__content {
                margin-left: 80px;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                .el-button {
                    background: #D31E0E;
                    color: #fff;
                    margin-left: 20px;
                }
                p {
                    cursor: pointer;
                    color: #666;
                }

            }
        }
    }
    .sortPageBox {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 46px;
        background-color: #fff;
        padding: 0 18px;

        span {
            width: auto;
            height: 40px;
            margin-right: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: $mainFont;
            cursor: pointer;

            .icon {
                margin-left: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                i {
                    font-size: 10px;
                    color: #C1C1C1;
                    transform: translateY(-2px);
                }

                i:first-of-type {
                    transform: translateY(2px);
                }

                .active {
                    color: #D31E0E;
                }
            }
        }

        span.active {
            border-bottom: 1px solid $themeColors;
            color: $themeColors;
        }
        .collection-type {
            margin-left: auto;
            img {
                vertical-align: middle;
                width: 30px;
                height: 30px;
                margin-left: 15px;
            }
        }
    }

    .collection-card {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
