<template>
  <div class="card" :class="[collectionItem.projectStatus != 2 ? 'has-closed' : 'register']">
    <div>
      <h4 class="elp-2">
        {{ collectionItem.projectName }}
      </h4>
      <p class="company">
        {{ collectionItem.publishCmpName }}
      </p>
      <div class="collection-info">
        <span>{{ collectionItem.goodsName }}</span>
      </div>
      <p class="collection-item">
        <span class="collection-item-label">集采总量:</span> <span class="collection-item-value">{{ collectionItem.centralizedPurchaseTotalQuantity }}{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), collectionItem.meterageUnit) }}</span>
      </p>
      <p class="collection-item">
        <span class="collection-item-label">所在地区:</span> <span class="collection-item-value">{{ $getDictNameByData($store.getters.getDictionaryItem('PROVINCE'), collectionItem.placeProvince) }}</span>
      </p>
      <p class="collection-item">
        <span class="collection-item-label">报名截止:</span> <span class="collection-item-value time">{{ collectionItem.enterEndDate }}&nbsp;23:59</span>
      </p>
      <p class="collection-price">
        <span class="price">￥{{ collectionItem.centralizedPurchasePrice }}</span><span>/{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), collectionItem.meterageUnit) }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    collectionItem: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="scss" scoped>
.has-closed {
    background: url('../../../assets/img/hasClosed.png') no-repeat top right;
}
.register {
    background: url('../../../assets/img/register.png') no-repeat top right;
}
.card {
    background-color: #fff;
    padding: 32px 24px 28px;
    width: 240px;
    position: relative;
    margin-bottom: 16px;
    margin-right: 16px;

    h4 {
        font-size: 24px;
        word-wrap:break-word;
    }
    .company {
        line-height: 28px;
        font-size: 14px;
        color: #666;
    }
    .collection-info {
        margin: 20px 0;
        span {
            font-size: 14px;
            background: rgba(211,30,14,0.08);
            color: #D31E0E;
            padding: 5px;
        }
    }
    .collection-item {
        height: 30px;
        line-height: 30px;
        &-label {
            color: #666;
        }
        &-value {
            color: #333;
            margin-left: 10px;
        }
        .time {
            color: #000;
            font-weight: 500;
        }
    }
    .collection-price {
        margin: 25px 0 0;
        .price {
            font-size: 27px;
            font-weight: 500;
            color: #D31E0E;
        }
    }

}
.card:nth-of-type(4n){
    margin-right: 0;
}
.card:hover {
    box-shadow: 0 3px 30px 0 rgba(0,0,0,0.05), 0 8px 24px 0 rgba(0,0,0,0.04), 0 4px 10px 0 rgba(0,0,0,0.04);

}
</style>
