<template>
  <div class="collection-row-item">
    <div class="collection-row-title">
      <h4 class="elp-1">
        {{ collectionItem.projectName }}
      </h4>
      <div class="collection-info">
        <span>{{ collectionItem.goodsName }}</span>
      </div>
      <p class="company">
        {{ collectionItem.publishCmpName }}
      </p>
    </div>
    <p>所在地区： {{ $getDictNameByData($store.getters.getDictionaryItem('PROVINCE'), collectionItem.placeProvince) }}</p>
    <div class="price-info">
      <p class="collection-price">
        <span class="price">￥{{ collectionItem.centralizedPurchasePrice }}</span><span>/{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), collectionItem.meterageUnit) }}</span>
      </p>
      <p>集采总量: {{ collectionItem.centralizedPurchaseTotalQuantity }}{{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), collectionItem.meterageUnit) }}</p>
    </div>
    <div class="timer-box">
      距结束
      <div class="timer">
        <span>{{ enterEndDate.day || '00' }}</span>天<span>{{ enterEndDate.hour || '00' }}</span>时<span>{{ enterEndDate.minutes || '00' }}</span>分<span>{{ enterEndDate.second || '00' }}</span>秒
      </div>
    </div>
    <el-button class="view">
      查看详情
    </el-button>
  </div>
</template>
<script>
import { countDownFn } from '@/unit/index'
export default {
  props: {
    collectionItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timer: null,
      enterEndDate: {}
    }
  },
  watch: {
    collectionItem: {
      handler(newVal) {
        window.clearInterval(this.timer)
        // 先调用一次是防止出现000
        this.enterEndDate = countDownFn(`${newVal.enterEndDate} 23:59:59`, 'retrunObj')
        this.timer = setInterval(() => {
          this.enterEndDate = countDownFn(`${newVal.enterEndDate} 23:59:59`, 'retrunObj')
        }, 1000)
      },
      immediate: true,
      deep: true
    }
  },
  destroyed() {
    window.clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="scss" scoped>
.collection-row-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E7E7E7;
    padding: 20px 24px;
    background: #fff;
    .timer-box{
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 14px;
      .timer{
        display: flex;
        align-items: center;
        color: #666;
        span{
          margin: 0 8px;
          color: #333;
          font-size: 22px;
        }
      }
    }
    .company {
        line-height: 28px;
        font-size: 14px;
        color: #666;
    }
    .collection-info {
        margin: 10px 0;
        span {
            font-size: 14px;
            background: rgba(211,30,14,0.08);
            color: #D31E0E;
            padding: 5px;
        }
    }
    .price-info {
        .collection-price {
            // margin: 25px 0;
            .price {
                font-size: 27px;
                font-weight: 500;
                color: #D31E0E;
            }
        }
        p {
            color: #666;
            margin: 10px 0;
        }
    }
    .view {
        color: #D31E0E;
    }
    .view:hover {
        background-color: #D31E0E;
        color: #fff;
    }
}
.collection-row-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0.4;
    h4 {
        font-size: 15px;
    }
}
.collection-row-item:hover {
    margin-bottom: 1px;
    border-bottom: none;
    box-shadow: 0 3px 0 0 rgba(0,0,0,0.05), 0 8px 24px 0 rgba(0,0,0,0.04), 0 4px 10px 0 rgba(0,0,0,0.04);
}
</style>
